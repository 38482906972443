<template>
  <div
    class="fixed top-0 bottom-0 left-0 right-0 bg-black opacity-60 z-[600]"
  ></div>
  <div
    class="z-[601]"
    :class="
      isMobileScreen && mobileBottomSheet
        ? 'fixed left-0 bottom-0'
        : `fixed top-0 bottom-0 left-0 right-0 pt-xl md:p-[4%]`
    "
  >
    <ContainerBasic :container-type="EContainerType.CONTENT" classes="h-full">
      <div class="relative flex items-center justify-center h-full">
        <div
          class="relative w-full md:max-w-lg min-w-[345px] max-h-full h-full overflow-visible md:flex md:flex-col md:justify-center"
        >
          <div
            ref="el"
            class="md:h-auto md:max-h-[100%] bg-white overflow-visible"
          >
            <div class="sticky top-0 z-510">
              <div
                class="bg-white px-sm"
                :class="{
                  'flex justify-center items-center border-b-1 border-vdv-border-light pb-sm':
                    isScrolled,
                }"
              >
                <HeadlineDynamic
                  v-if="dialogStore.headline"
                  :headline="dialogStore.headline"
                  level="h4"
                  :has-decoration="!isScrolled"
                  container-classes="w-full pt-md"
                  classes="break-keep whitespace-break-spaces"
                />
                <button
                  type="button"
                  class="absolute text-xl cursor-pointer text-vdv-border-dark top-sm right-md"
                  @click="dialogStore.closeDialog(DialogResponses.CANCEL)"
                >
                  <FaIcon icon-class="fas fa-xmark" />
                </button>
              </div>
            </div>
            <slot />
          </div>
        </div>
      </div>
    </ContainerBasic>
  </div>
</template>
<script setup lang="ts">
import ContainerBasic from '@/components/components/container-basic.vue';
import { useDialogStore, DialogResponses } from '~/stores/useDialogStore';
import { EContainerType } from '~~/src/@types/container-type';
import FaIcon from '@/components/fa-icon.vue';
import HeadlineDynamic from '@/components/components/core/headline-dynamic/vdv/headline-dynamic.vue';
import { SSR_safe_mq_breakpointIsMobile } from '~/injectionSymbols';

const el = ref<HTMLElement | null>(null);

const { y } = useScroll(el);
const dialogStore = useDialogStore();

const isMobileScreen = inject(SSR_safe_mq_breakpointIsMobile);

defineProps({
  mobileBottomSheet: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const isScrolled = computed(() => {
  return y.value > 10;
});

onMounted(() => {
  usePreventBodyScroll(true);
});

onUnmounted(() => {
  usePreventBodyScroll(false);
});

watch(useRouter().currentRoute, () => {
  if (dialogStore.open) dialogStore.closeDialog();
});
</script>
